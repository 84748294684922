import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { BiWallet } from 'react-icons/bi';
import { RiMastercardFill } from 'react-icons/ri';
import { BiLogOut } from 'react-icons/bi';
import "../css/wallet.css";

function Wallet() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const hamburgerButton = document.querySelector('.wallet-hamburger-menu');
      const mobileMenu = document.querySelector('.wallet-mobile-menu');
      
      if (isMenuOpen && mobileMenu && !mobileMenu.contains(event.target) && 
          hamburgerButton && !hamburgerButton.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleWalletClick = () => {
    console.log('Wallet clicked');
    setIsMenuOpen(false);
  };

  const handleTarjetasClick = () => {
    // Por ahora solo cerramos el menú
    setIsMenuOpen(false);
    // TODO: Agregar redirección cuando esté lista la página
  };

  const handleLogout = () => {
    navigate('/quienes-somos');
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="wallet-page">
      <Navbar />
      <div className="wallet-content">
        <button className="wallet-hamburger-menu" onClick={toggleMenu}>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>

        <div className={`wallet-mobile-menu ${isMenuOpen ? 'active' : ''}`}>
          <button className="mobile-menu-close" onClick={toggleMenu}>
            <span></span>
            <span></span>
          </button>
          <div className="mobile-menu-buttons">
            <button onClick={handleWalletClick} className="mobile-menu-button">
              <BiWallet className="button-icon" />
              <span>Wallet</span>
            </button>
            <button onClick={handleTarjetasClick} className="mobile-menu-button">
              <RiMastercardFill className="button-icon" />
              <span>Comprar Tarjeta</span>
            </button>
            <button onClick={handleLogout} className="mobile-menu-button logout">
              <BiLogOut className="button-icon" />
              <span>Cerrar Sesión</span>
            </button>
          </div>
        </div>

        <div className="wallet-sidebar">
          <div className="sidebar-buttons">
            <button className="sidebar-button" onClick={handleWalletClick}>
              <BiWallet className="button-icon" />
              <span>Wallet</span>
            </button>
            <button className="sidebar-button" onClick={handleTarjetasClick}>
              <RiMastercardFill className="button-icon" />
              <span>Comprar Tarjeta</span>
            </button>
            <button className="logout-button" onClick={handleLogout}>
              <BiLogOut className="button-icon" />
              <span>Cerrar Sesión</span>
            </button>
          </div>
        </div>
        <div className="wallet-main-content">
          {/* Aquí irá el contenido principal de la wallet */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Wallet; 